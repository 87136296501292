import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import HomePage from './HomePage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Team from './pages/team/Team';
import Car from './pages/car/Car';
import Events from './pages/events/Events';
import Media from './pages/media/Media';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
        <Route 
          path="/" 
          Component={HomePage}
        />
        <Route 
          path="/team" 
          Component={Team}
        />
         <Route 
          path="/media" 
          Component={Media}
        />
        <Route 
          path="/car" 
          Component={Car}
        />
        <Route 
          path="/events" 
          Component={Events}
        />
      </Routes>
  </BrowserRouter>
);

