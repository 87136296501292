import Nav from "./components/nav/Nav";
import { Footer } from "./components/footer/Footer";
import ItWorks from "./resources/images/itworks.jpg"
import { CarTable } from "./components/car-table/CarTable";


const headerItems = ['Label', 'Fact'];
const tableItems = [
  ['Year', '2002'],
  ['Make', 'Volkswagen'],
  ['Model', 'Golf GL'],
  ['Doors', 'Two'],
  ['Fuel Type', 'Gas'],
  ['Horsepower', '115 @ 5200 RPM (probably 80-90 now)'],
  ['Torque', '122 lb-ft'],
  ['0-60 MPH', '10.1 seconds'],
  ['Engine Displacement', '2.0 NA'],
  ['Cylinders', 'Four'],
  ['Transmission', 'Manual'],
  ['Air Bags', 'No'],
  ['Roll Cage', 'Yes'],
  ['Fire Suppression', 'Yes'],
  ['Team/Car Theme', 'Jaws (The Film)'],
  ['MSRP in 2002', '$15,600'],
  ["", ""],
];

function HomePage() {
  return (
    <div className="container">
      <Nav></Nav>

      <div className="team-member-well p-2">
        <h2 className="text-center">Welcome! You found our very good website. </h2>

        <p>
          We are The Wicked Fast And Furious, and we are racing Lemons in Loudon on October 19th/20th!  Over the last several months, 
          a determined team of men and women with various names amd skills took an old, rusty, mouse infested 
          Mk IV Golf and turned it into the fastest slowest thing on 4 wheels. 
        </p>

        <div className="home-img-container">
          <img style={{maxWidth:"50%"}} src={ItWorks}></img>
        </div>

        <p>
        Our theme for this race is going to be centered around the 1975 classic thriller film <a href="https://en.wikipedia.org/wiki/Jaws_(film)">Jaws</a>.  
        Our team members will be making up residents of Amity Island and the crew of the Orca, and the car will be Bruce, the shark. 
        Though hopefully with <a href="https://jaws.fandom.com/wiki/Bruce">fewer mechnical problems than the real Bruce</a> so we can appear on the racetrack instead of racing as an intimidating musical theme... 
        </p>


        <p>
        
        </p>



        <p>Here is a table with awesome facts about our awesome race car:</p>

        <CarTable
          headerItems={headerItems}
          items={tableItems}
        />
        
      </div>

      <Footer/>
    </div>
  );
}

export default HomePage;
