import { Component, React } from "react";
import NavBtn from "./nav-btn/NavBtn";



class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <div className="nav-container team-member-well">
                <h1>The Wicked Fast and Furious</h1>
                <div className="nav">
                    <NavBtn
                        text="Home"
                        url="/"
                    />
                    <NavBtn
                        text="Team"
                        url="/team"
                    />
                     <NavBtn
                        text="Media"
                        url="/media"
                    />
                    <NavBtn
                        text="Car"
                        url="/car"
                    />
                    <NavBtn
                        text="Events"
                        url="/events"
                    />
                </div>
            </div>

        );
    }
}

export default Nav;
