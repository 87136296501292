import { Footer } from "../../components/footer/Footer";
import Nav from "../../components/nav/Nav";

import GolfPurchase from "../../resources/images/gallery/IMG_2021.jpg"
import LemonsLogo from "../../resources/images/gallery/24HoursofLemonsLogo.png"
import InteriorDisassembly from "../../resources/images/gallery/IMG_2694.jpg"
import BrokenPipes from "../../resources/images/gallery/IMG_2695.jpg"
import Victory from "../../resources/images/gallery/IMG_1319.jpg"
import GolfStart from "../../resources/images/gallery/videos/LemonsGolfStart.mp4"
import OutOfTheGarage from "../../resources/images/gallery/videos/OutOfTheGarage.mp4"
import UnleashThePower from "../../resources/images/gallery/videos/UnleashThePower.mp4"
import SuchSpeed from "../../resources/images/gallery/videos/SuchSpeed.mp4"

function Car() {

  return (
    <div className="container">
      <Nav></Nav>

      <div className="row team-member-well">
        <div className="col-12">
          <h1>The Car</h1>
          <h2>aka The Lemons Golf</h2>

          <p>
            You may be asking yourself, "what group of dummies decides to take a Mk IV Golf GL with the big 2.5L and turn it into a race car?"  
            The answer to your questions is "these dummies."  Our story begins in March of 2024 as Jake and Chris decide to try and build 
            a Lemons car, and begin searching Central and Northern Massachusetts for a suitable vehicle.  Experienced amateur techs who have 
            watched multiple of their own cars slowly rot away, they knew were in for a slog. They spent countless hours searching for the perfect
            machine, trying to contact sketchy craigslist and marketplace listings, meeting new people and discovering they were not willing to part with
            their machines easily because "they know what they have."  It was still cold out and dropping onto the mud and gravel to look underneath the
            various rustbuckets also wasn't very fun.  Despite not yet having a car, they contacted more car friends, Amanda and Nils, and asked them
            to be a part of their team.  Their response was "sure, we don't know what you're talking about really, but we love rusty junk." Clearly crazy
            enough to be a part of the team!
           </p>

          <img style={{maxWidth:"80%", display:"block", marginLeft:"auto", marginRight:"auto"}} src={LemonsLogo}/>
          <p className="text-center">"You race broken down junk that you fixed and other cars can hit you, trust us it's blast even though we've never done it!"</p>

          <p>
            Call it fate, call it destiny, call it statistics, but one day as Chris drove past Route 3 to the Shell gas station near his house, he noticed an
            old Golf with a FOR SALE BY OWNER sign sitting with a flat tire in the mud sitting next to a guy's house.  Pulling over with his irritated wife and child, 
            he snapped a few pics of the car, only to return the next day with Jake to give it a once over and see if we could get it going.  We blasted some air into the tire,
            stuck a battery in it, stuck a charger on it, and managed to get it started!  It sounded terrible, did not steer, had 240k miles on the motor,
            and it was entirely full of mice and their waste.  The brakes were completely fused, and the shocks empty.  A dump sticker was proudly affixed to the windshield
            telling us how this car had used the last few useful years of it's life before it got parked.  This was our car.  We took the car back to Chris's house.
          </p>

          <img style={{maxWidth:"80%", display:"block", marginLeft:"auto", marginRight:"auto"}} src={GolfPurchase}/>
          <p className="text-center">A car!</p>

          <p>
            Since the car was already at Chris's house, the team decided to use his garage to work on the car.  After getting it on stands, they proceeded to completely
            rip it to shreds.  Everything had to come apart, including the subframe, all 4 corners, the motor to fix the timing, and the transmission to 
            fix the broken clutch and shifter.  Oh yeah, the shifter just liked to flop to the side when in neutral, which made driving the car a fun adventure until that repair.
          </p>

          <img style={{maxWidth:"80%", display:"block", marginLeft:"auto", marginRight:"auto"}} src={InteriorDisassembly}/>
          <p className="text-center">What interior?</p>

          <img style={{maxWidth:"80%", display:"block", marginLeft:"auto", marginRight:"auto"}} src={BrokenPipes}/>
          <p className="text-center">Quality reusable parts.</p>

          <p>
            We started in March, and continued through the Summer in the naturally aspirated garage/sauna.  Finally, after a few weekends of reassembly, we were ready for a static test.
          </p>

          <div style={{maxWidth:"80%", display:"block", marginLeft:"auto", marginRight:"auto"}} className="row p-2">
          <video controls>
            <source src={GolfStart} type="video/mp4" />
          </video>
          <p className="text-center">Static start after reassembly!</p>
          </div>

          <p>
            We spent a couple more weekends sealing up all the fluidic systems, putting the corners and front back on, and prepared to roll it out of the garage for the first time in almost half a year.
          </p>

          <img style={{maxWidth:"80%", display:"block", marginLeft:"auto", marginRight:"auto"}} src={Victory}/>
          <p className="text-center">We had some extra help along the way too.</p>

          <div style={{maxWidth:"80%", display:"block", marginLeft:"auto", marginRight:"auto"}} className="row p-2">
          <video controls>
            <source src={OutOfTheGarage} type="video/mp4" />
          </video>
          <p className="text-center">It's out!</p>
          </div>

          <p>
            We then took it to our local shuttered IBM office park/test track for a shakedown.
          </p>

          <div style={{maxWidth:"80%", display:"block", marginLeft:"auto", marginRight:"auto"}} className="row p-2">
          <video controls>
            <source src={UnleashThePower}type="video/mp4" />
          </video>
          <p className="text-center">Unleashing the power</p>
          </div>

          <div style={{maxWidth:"80%", display:"block", marginLeft:"auto", marginRight:"auto"}} className="row p-2">
          <video controls>
            <source src={SuchSpeed} type="video/mp4" />
          </video>
          <p className="text-center">Maximum Performance</p>
          </div>

          <p>
            Now that the car is restored to some approximation of spec, ish, we've been installing the required safety equipment and themeing the car for our race. 
            We don't have any pictures of this yet, because we're actively still in progress, but we will post some soon!  We are going to theme our team/car as the boat
            and crew from the 1975 thriller film Jaws.  Jake is Chief Brody, Chris is Captain Quint, Nils is Matt Hooper, Amanda is Mayor Vaughn ("the beaches are open/the cars
            are perfectly fine!"), and the car is Bruce The Shark.  We promise nothing is going to fall off of the car, probably.  Chris is extremely paranoid about stuff like that.

            In our day jobs, we're all engineers of various kinds, though mostly in the computers and software world.  Working on a project like this has been one of the more
            rewarding and valuable things we've ever done, and we really excited to run Lemons in Loudon this year!
          </p>

         </div>

      </div>

      <Footer/>

    </div>
  );
}

export default Car;
