import Nav from "../../components/nav/Nav";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original:"/images/gallery/IMG_1319.jpg",
    thumbnail:"/images/gallery/thumb-IMG_1319.jpg",
  },
  {
      original:"/images/gallery/IMG_1994.jpg",
      thumbnail:"/images/gallery/thumb-IMG_1994.jpg",
  },
  {
      original:"/images/gallery/IMG_1995.jpg",
      thumbnail:"/images/gallery/thumb-IMG_1995.jpg",
  },
  {
      original:"/images/gallery/IMG_1996.jpg",
      thumbnail:"/images/gallery/thumb-IMG_1996.jpg",
  },
  {
      original:"/images/gallery/IMG_1997.jpg",
      thumbnail:"/images/gallery/thumb-IMG_1997.jpg",
  },
  {
      original:"/images/gallery/IMG_1998.jpg",
      thumbnail:"/images/gallery/thumb-IMG_1998.jpg",
  },
  {
      original:"/images/gallery/IMG_1999.jpg",
      thumbnail:"/images/gallery/thumb-IMG_1999.jpg",
  },
  {
      original:"/images/gallery/IMG_2001.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2001.jpg",
  },
  {
      original:"/images/gallery/IMG_2021.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2021.jpg",
  },
  {
      original:"/images/gallery/IMG_2022.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2022.jpg",
  },
  {
      original:"/images/gallery/IMG_2671.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2671.jpg",
  },
  {
      original:"/images/gallery/IMG_2672.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2672.jpg",
  },
  {
      original:"/images/gallery/IMG_2674.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2674.jpg",
  },
  {
      original:"/images/gallery/IMG_2680.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2680.jpg",
  },
  {
      original:"/images/gallery/IMG_2681.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2681.jpg",
  },
  {
      original:"/images/gallery/IMG_2686.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2686.jpg",
  },
  {
      original:"/images/gallery/IMG_2690.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2690.jpg",
  },
  {
      original:"/images/gallery/IMG_2691.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2691.jpg",
  },
  {
      original:"/images/gallery/IMG_2692.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2692.jpg",
  },
  {
      original:"/images/gallery/IMG_2694.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2694.jpg",
  },
  {
      original:"/images/gallery/IMG_2695.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2695.jpg",
  },
  {
      original:"/images/gallery/IMG_2718.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2718.jpg",
  },
  {
      original:"/images/gallery/IMG_2719.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2719.jpg",
  },
  {
      original:"/images/gallery/IMG_2720.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2720.jpg",
  },
  {
      original:"/images/gallery/IMG_2738.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2738.jpg",
  },
  {
      original:"/images/gallery/IMG_2746.jpg",
      thumbnail:"/images/gallery/thumb-IMG_2746.jpg",
  },
  {
      original:"/images/gallery/IMG_3436.jpg",
      thumbnail:"/images/gallery/thumb-IMG_3436.jpg",
  },
  {
      original:"/images/gallery/IMG_3437.jpg",
      thumbnail:"/images/gallery/thumb-IMG_3437.jpg",
  },
  {
      original:"/images/gallery/IMG_3438.jpg",
      thumbnail:"/images/gallery/thumb-IMG_3438.jpg",
  },
  {
      original:"/images/gallery/IMG_3439.jpg",
      thumbnail:"/images/gallery/thumb-IMG_3439.jpg",
  },
  {
      original:"/images/gallery/IMG_3499.jpg",
      thumbnail:"/images/gallery/thumb-IMG_3499.jpg",
  },
  {
      original:"/images/gallery/IMG_3502.jpg",
      thumbnail:"/images/gallery/thumb-IMG_3502.jpg",
  },
  {
      original:"/images/gallery/IMG_3503.jpg",
      thumbnail:"/images/gallery/thumb-IMG_3503.jpg",
  },
  {
      original:"/images/gallery/IMG_3505.jpg",
      thumbnail:"/images/gallery/thumb-IMG_3505.jpg",
  },
  {
      original:"/images/gallery/IMG_3506.jpg",
      thumbnail:"/images/gallery/thumb-IMG_3506.jpg",
  },
  {
      original:"/images/gallery/IMG_4474.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4474.jpg",
  },
  {
      original:"/images/gallery/IMG_4475.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4475.jpg",
  },
  {
      original:"/images/gallery/IMG_4869.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4869.jpg",
  },
  {
      original:"/images/gallery/IMG_4897.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4897.jpg",
  },
  {
      original:"/images/gallery/IMG_4898.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4898.jpg",
  },
  {
      original:"/images/gallery/IMG_4904.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4904.jpg",
  },
  {
      original:"/images/gallery/IMG_4905.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4905.jpg",
  },
  {
      original:"/images/gallery/IMG_4943.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4943.jpg",
  },
  {
      original:"/images/gallery/IMG_4944.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4944.jpg",
  },
  {
      original:"/images/gallery/IMG_4951.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4951.jpg",
  },
  {
      original:"/images/gallery/IMG_4952.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4952.jpg",
  },
  {
      original:"/images/gallery/IMG_4953.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4953.jpg",
  },
  {
      original:"/images/gallery/IMG_4954.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4954.jpg",
  },
  {
      original:"/images/gallery/IMG_4955.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4955.jpg",
  },
  {
      original:"/images/gallery/IMG_4956.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4956.jpg",
  },
  {
      original:"/images/gallery/IMG_4957.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4957.jpg",
  },
  {
      original:"/images/gallery/IMG_4958.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4958.jpg",
  },
  {
      original:"/images/gallery/IMG_4959.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4959.jpg",
  },
  {
      original:"/images/gallery/IMG_4960.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4960.jpg",
  },
  {
      original:"/images/gallery/IMG_4963.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4963.jpg",
  },
  {
      original:"/images/gallery/IMG_4964.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4964.jpg",
  },
  {
      original:"/images/gallery/IMG_4968.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4968.jpg",
  },
  {
      original:"/images/gallery/IMG_4969.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4969.jpg",
  },
  {
      original:"/images/gallery/IMG_4970.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4970.jpg",
  },
  {
      original:"/images/gallery/IMG_4971.jpg",
      thumbnail:"/images/gallery/thumb-IMG_4971.jpg",
  },
  {
      original:"/images/gallery/IMG_5067.jpg",
      thumbnail:"/images/gallery/thumb-IMG_5067.jpg",
  },
  {
      original:"/images/gallery/IMG_5068.jpg",
      thumbnail:"/images/gallery/thumb-IMG_5068.jpg",
  },
  {
      original:"/images/gallery/IMG_5069.jpg",
      thumbnail:"/images/gallery/thumb-IMG_5069.jpg",
  },
  {
      original:"/images/gallery/IMG_5070.jpg",
      thumbnail:"/images/gallery/thumb-IMG_5070.jpg",
  },
  {
      original:"/images/gallery/IMG_5071.jpg",
      thumbnail:"/images/gallery/thumb-IMG_5071.jpg",
  },
];

function Media() {
  return (
    <div className="container">
      <Nav></Nav>

      <div className="team-member-well">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">Photos and Videos!</h2>
          </div>
        </div>

        <div className="row p-2" >
          <ImageGallery items={images} />;
        </div>

        <div className="row p-2">
          <video controls>
            <source src="/images/gallery/videos/LemonsGolfStart.mp4" type="video/mp4" />
          </video>
          <p className="text-center">Static start after reassembly!</p>

          <video controls>
            <source src="/images/gallery/videos/FillingWithCoolant.mp4" type="video/mp4" />
          </video>
          <p className="text-center">Loading with coolant sealing up all the fluids!</p>

          <video controls>
            <source src="/images/gallery/videos/OutOfTheGarage.mp4" type="video/mp4" />
          </video>
          <p className="text-center">Rolling out of the garage!</p>

          <video controls>
            <source src="/images/gallery/videos/NoInterior.mp4" type="video/mp4" />
          </video>
          <p className="text-center">No interior best interior (less mice)!</p>

          <video controls>
            <source src="/images/gallery/videos/UnleashThePower.mp4" type="video/mp4" />
          </video>
          <p className="text-center">Taking off!</p>

          <video controls>
            <source src="/images/gallery/videos/SuchSpeed.mp4" type="video/mp4" />
          </video>
          <p className="text-center">Pushing the limits of performance!</p>
        </div>

      </div>

     
      {/* <Footer/> */}
      
    </div>
  );
}

export default Media;
